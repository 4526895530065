import {ModuleWithProviders, NgModule} from '@angular/core';

import {MathjaxHtmlDirective} from './mathjax-html.directive';
import {MathjaxHtmlService} from './mathjax-html.service';


@NgModule({
  declarations: [MathjaxHtmlDirective],
  exports: [MathjaxHtmlDirective]
})
export class MathjaxHtmlModule {

  constructor() {
    // const script = document.createElement('script') as HTMLScriptElement;
    // script.type = 'text/javascript';
    // script.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML';
    //
    // document.getElementsByTagName('head')[0].appendChild(script);

    // const config = document.createElement('script') as HTMLScriptElement;
    // config.type = 'text/x-mathjax-config';
    // config.text = `
    //   MathJax.Hub.Config({
    //     skipStartupTypeset: true
    //   });
    //   MathJax.Hub.Register.StartupHook('End', () => {
    //     window.hubReady.next();
    //     window.hubReady.complete();
    //   });
    // `;

    // document.getElementsByTagName('head')[0].appendChild(config);
  }

  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: MathjaxHtmlModule,
      providers: [
        {
          provide: MathjaxHtmlService,
          useClass: MathjaxHtmlService
        }
      ]
    };
  }
}
