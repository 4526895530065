import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

import {MathjaxHtmlService} from './mathjax-html.service';


@Directive({
  selector: '[innerMathjaxHTML]'
})
export class MathjaxHtmlDirective implements OnInit, OnChanges, OnDestroy {

  @Input() innerMathjaxHTML: string;

  private alive$ = new Subject<boolean>();
  private readonly htmlElement: HTMLElement;

  constructor(
    private service: MathjaxHtmlService,
    private el: ElementRef
  ) {
    this.htmlElement = el.nativeElement as HTMLElement;
  }

  ngOnInit(): void {
    this.service
      .ready()
      .pipe(
        take(1),
        takeUntil(this.alive$)
      )
      .subscribe(() => {
        this.service.render(this.htmlElement, this.innerMathjaxHTML);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {currentValue, firstChange} = changes.innerMathjaxHTML;

    this.service.render(this.htmlElement, currentValue);
  }

  ngOnDestroy(): void {
    this.alive$.next(false);
  }
}
