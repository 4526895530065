import {ChangeDetectorRef, Injectable} from '@angular/core';
import {Observable, Observer, ReplaySubject} from 'rxjs';

declare global {
  interface Window {
    hubReady: Observer<boolean>;
  }
}

@Injectable()
export class MathjaxHtmlService {

  private readonly notifier: ReplaySubject<boolean>;

  constructor() {
    this.notifier = new ReplaySubject<boolean>();
    window.hubReady = this.notifier;
  }

  ready(): Observable<boolean> {
    return this.notifier;
  }

  render(element: HTMLElement, content?: string): void {
    element.innerHTML = content;
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, element]);
  }

  forceUpdate(element: any) {
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, element]);
  }
}
